<template>
    <div class="card">
        <div class="card-header">Transport</div>
        <div class="card-body">
            <b-form-group :label="$t('crud.shipping_date')" style="width: 200px;">
                <b-form-input type="date" v-model="date">
                </b-form-input>
            </b-form-group>
            <loading v-if="loading"></loading>
            <table v-if="transporters.length" class="table">
                <tr>
                    <th>{{ $t('crud.transporters._singular') }}</th>
                    <th>{{ $t('crud.status') }}</th>
                    <th>{{ $t('crud.transport.open_sent_orders')}}</th>
                    <th>{{ $t('crud.transport.open_sent_pallets')}}</th>
                    <th>{{ $t('crud.actions') }}</th>
                </tr>
                <tr v-for="(transporter, index) in transporters">
                    <td><img :src="transporter.logo" :alt="transporter.name" style="width: 80px;"/></td>
                    <td>
                        <template v-for="transporter_dispatch in transporter.transporter_dispatches">
                            <p><small v-if="transporter_dispatch" class="text-muted">
                                {{ $t('crud.transport.dispatched_at', {dispatched_at: $options.filters.date(transporter_dispatch.dispatched_at, true)}) }}
                            </small></p>
                        </template>
                    </td>
                    <td>{{ transporter.order_splits_count }} / {{ transporter.dispatched_order_splits_count }}</td>
                    <td>{{ transporter.order_line_deliveries_count }} / {{ transporter.dispatched_order_line_deliveries_count }}</td>
                    <td style="width: 1px" v-if="transporter.name_key !== 'in_house'">
                        <div class="btn-group btn-group-sm">
                            <router-link v-if="transporter.orders.length" :to="{name: 'orders.index', query: {filters: JSON.stringify({ids: transporter.orders.map((order) => { return order.id }) }) }}" class="btn btn-light"><i class="fa fa-search"></i><br/> {{ $t('crud.transport.view_orders') }}</router-link>
                            <a v-else href="#" class="btn btn-light disabled"><i class="fa fa-search"></i><br/> {{ $t('crud.transport.view_orders') }}</a>

                            <loading-button class="btn btn-light" @click="resetDispatch(transporter.id, index)" :loading="loadingReset[index]"><i class="fa fa-reply"></i><br/> {{ $t('crud.transport.reset_orders') }}</loading-button>
                            <loading-button :class="['btn', transporter.order_splits_count ? 'btn-primary' : 'btn-light']" @click="sendDispatch(transporter.id, index)" :loading="loadingSend[index]"><i class="fa fa-share"></i><br/> {{ $t('crud.transport.send_orders') }}</loading-button>

                            <b-dropdown :disabled="!transporter.transporter_dispatches.length" variant="light">
                                <b-dropdown-item v-for="transporter_dispatch in transporter.transporter_dispatches" :key="transporter_dispatch.id" @click="resendDispatch(transporter_dispatch.id)" :title="transporter_dispatch.dispatch_key">
                                    <small>
                                        {{ transporter_dispatch.dispatch_key }}<br />
                                        {{ transporter_dispatch.dispatched_at | date(true) }}
                                    </small>
                                </b-dropdown-item>
                                <template slot="button-content">
                                    <i class="fa fa-file-pdf-o"></i> {{ $t('crud.transport.resend_orders') }}
                                </template>
                            </b-dropdown>

                            <b-dropdown :disabled="!transporter.transporter_dispatches.length" variant="light">
                                <template v-for="transporter_dispatch in transporter.transporter_dispatches">
                                    <template v-for="page in Math.ceil(transporter_dispatch.order_line_deliveries_count / 50)">
                                        <b-dropdown-item @click="pdf(`/pdf/labels/${transporter_dispatch.id}?page=${page}`)" :title="transporter_dispatch.dispatch_key">
                                            <small>
                                                {{ transporter_dispatch.dispatched_at | date(true) }}<br />
                                                <b>( {{ page }} / {{ Math.ceil(transporter_dispatch.order_line_deliveries_count / 50) }} )</b>
                                            </small>
                                        </b-dropdown-item>
                                    </template>
                                </template>
                                <template slot="button-content">
                                    <i class="fa fa-file-pdf-o"></i> {{ $t('crud.transport.shipping_labels') }}
                                </template>
                            </b-dropdown>
                        </div>
                    </td>
                    <td v-else>
                        <div class="btn-group btn-group-sm">
                            <router-link v-if="transporter.orders.length" :to="{name: 'orders.index', query: {filters: JSON.stringify({ids: transporter.orders.map((order) => { return order.id }) }) }}" class="btn btn-light"><i class="fa fa-search"></i><br/> {{ $t('crud.transport.view_orders') }}</router-link>
                            <a v-else href="#" class="btn btn-light disabled"><i class="fa fa-search"></i><br/> {{ $t('crud.transport.view_orders') }}</a>

                            <button @click="pdf(`/pdf/shipping-document/${date}`)" class="btn btn-light"><i class="fa fa-file-pdf-o"></i><br/> {{ $t('crud.pdf.shipping_document') }}</button>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        mounted() {
            this.date = this.$route.query.date || this.$moment().format('YYYY-MM-DD');
        },
        data() {
            return {
                transporters: [],
                date: undefined,
                loading: false,
                loadingReset: [false, false, false, false],
                loadingSend: [false, false, false, false]
            };
        },
        watch: {
            date() {
                history.replaceState(null, null, document.location.pathname + '#' + this.$route.path + '?date=' + this.date);
                this.getData();
            }
        },
        methods: {
            getData() {
                this.loading = true;
                return this.$http.get('/transport' + (this.date ? '/' + this.date : '')).then((response) => {
                    this.transporters = response.data;
                    this.loading = false;
                }).catch(() => { this.loading = false });
            },
            /**
             * Direct the user to a PDF on the backend API. The JWT token is appended in the url for access.
             * @param {String} url
             */
            pdf(url) {
                // Add cookie for the duration of the browser session, because we open a PDF file directly from the API.
                // The default authentication is in the Authorization header (which is not available for GET-requests in this form).
                this.$cookie.set('token', this.$auth.token());

                if(url.includes('?')) {
                    window.open(this.$config.VUE_APP_API_URL + url + '&cachebust=' + Date.now(), '_blank');
                } else {
                    window.open(this.$config.VUE_APP_API_URL + url + '?cachebust=' + Date.now(), '_blank');
                }
            },
            /**
             * @param {Number} transporterId
             * @param {Number} [index] Used for loading indicator.
             */
            resetDispatch(transporterId, index)
            {
                if(confirm(this.$t('crud.transport.reset_dispatch_confirm'))) {
                    this.$set(this.loadingReset, index, true);
                    this.$http.post('/transport/reset/' + transporterId + '/' + this.date)
                        .then(this.getData)
                        .then(() => {
                            this.$set(this.loadingReset, index, false);
                        }).catch(() => {
                        this.$set(this.loadingReset, index, false);
                    });
                }
            },
            resendDispatch(transporterDispatchId)
            {
                if(confirm(this.$t('crud.transport.resend_dispatch_confirm'))) {
                    return this.$http.post(`/transport/resend/${transporterDispatchId}`).then(() => {
                        this.$toastr.s(this.$t('crud.transport.resend_dispatch_success'));
                    });
                }
                return Promise.reject();
            },
            /**
             * @param {Number} transporterId
             * @param {Number} [index] Used for loading indicator.
             */
            sendDispatch(transporterId, index)
            {
                if(confirm(this.$t('crud.transport.send_dispatch_confirm'))) {
                    this.$set(this.loadingSend, index, true);
                    this.$http.post('/transport/send/' + transporterId + '/' + this.date)
                        .then((response) => {
                            this.$toastr.s(this.$t('crud.transport.send_dispatch_success', { count: response.data.sent_items}));
                        })
                        .then(this.getData)
                        .then(() => {
                            this.$set(this.loadingSend, index, false);
                        }).catch(() => {
                        this.$set(this.loadingSend, index, false);
                    });
                }
            }
        }
    }
</script>
